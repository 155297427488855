<template>
	<v-layout class="py-3 px-3">
		<div class="pa-4 rounded-xl">
			<div class="d-flex align-start">
				<v-icon size="35">mdi-whatsapp</v-icon>

				<div class="ml-3">
					<span class="text-h5 fw-700 primary--text fw-700 text-h5">WhatsApp</span>
					<div class="mt-3">
						Connect WhatsApp business number and bring all your WhatsApp conversations inside Chat.
						<span class="blue--text lighten-3"> Learn More</span>
					</div>
				</div>
			</div>
			<v-divider class="mx-4 grey lighten-2 my-6"></v-divider>
			<div style="background-color: #f5f7f9" class="rounded-lg overflow-hidden border">
				<v-container>
					<v-layout row wrap>
						<v-flex xs12 sm12 lg8 class="pa-4">
							<span class="primary--text fw-500 text-h6"
								>Connect a new or existing WhatsApp Business number</span
							>
							<div class="my-6">
								<div>
									<div class="blue lighten-5 d-inline p-2 rounded-circle">
										<inline-svg style="width: 20px" :src="$assetURL('media/custom-svg/meta.svg')" />
									</div>
									<span class="ml-2"
										>Click on Start Setup to log in to your Facebook Business account.
									</span>
								</div>
								<div class="my-6">
									<div class="blue lighten-5 d-inline p-2 rounded-circle">
										<inline-svg style="width: 20px" :src="$assetURL('media/custom-svg/whatsapp-small.svg')" />
									</div>
									<span class="ml-2"
										>Follow the guided setup to connect your WhatsApp Business number or to register a new
										one.
									</span>
								</div>
								<div>
									<div class="blue lighten-5 d-inline p-2 rounded-circle">
										<inline-svg style="width: 20px" :src="$assetURL('media/custom-svg/party-icon.svg')" />
									</div>
									<span class="ml-2"
										>Click on Start Setup to log in to your Facebook Business account.
									</span>
								</div>
								<v-btn small class="mt-6 pa-5 start-setup-btn" color=" white--text" elevation="2">
									<v-icon size="15" left> mdi-arrow-right-bold </v-icon>
									<span class="font-weight-medium">Start Setup</span></v-btn
								>
							</div>
							<div>
								<span class="primary--text font-weight-bold">Note :</span>
								<span>
									Beyond 1000 conversations, WhatsApp charges per conversation, which includes all messages
									delivered in a 24 hour session. Know more on
								</span>
								<span class="blue--text">pricing</span>
							</div>
							<v-divider class="mx-4 grey lighten-2 my-6"></v-divider>
							<span
								>Looking to purchase a new number, or migrate from your current Service Provider or WhatsApp
								Business mobile app?
								<span class="blue--text fw-600s"
									><v-icon color="blue ">mdi-message-text</v-icon>Talk to our expert</span
								></span
							>
						</v-flex>
						<v-flex
							xs12
							sm12
							lg4
							style="background-color: #e0f5f"
							class="d-flex align-center flex-column justify-center blue lighten-5"
						>
							<div class="text-h6 px-26 text-lg-center mb-4">
								Watch the video to connect your WhatsApp account
							</div>
							<img
								class="pointer"
								@click="dialog = true"
								:src="$assetURL('media/misc/whatsapp-video-thubnail.png')"
							/>
						</v-flex>
					</v-layout>
				</v-container>
			</div>
		</div>

		<v-dialog open-delay="0" v-model="dialog" max-width="860">
			<v-card>
				<iframe
					width="860"
					height="600"
					src="https://www.youtube.com/embed/a5BU-Yj1J7w"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</v-card>
		</v-dialog>
	</v-layout>
</template>
<script>
export default {
	name: "CustomerSetting",
	data() {
		return {
			dialog: false,
		};
	},
};
</script>
